import React from "react";
import {
  Container,
  Nav,
  Navbar,
  ThemeProvider,
  Card,
  Row,
  Col,
} from "react-bootstrap";

import Footer from "../components/Footer";
import YoutubeEmbed from "../components/YoutubeEmbed";

const Homepage = () => {
  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      >
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="#home">CRYPTO CASINO</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse
              className="justify-content-end"
              id="responsive-navbar-nav"
            >
              <Nav>
                <Navbar.Text>
                  <a className="link-purple__log_in" href="/">
                    Log In
                  </a>
                  <a className="link-purple__sign-up ms-3" href="/">
                    Sign Up
                  </a>
                </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className="mt-5">
          <Row className="justify-content-center">
            <Col md={4}>
              <h1 className="crypto__title">
                ¡Bienvenido al Crypto Casino online!
              </h1>
              <p className="crypto__description">
                Crypto Casino es un casino con licencia en Curazao que cuenta
                con diversos juegos, que acepta crypto monedas (BTC, USDT y
                TruCash) Tiene soporte al cliente mediante email y Whatsapp. En
                él encontrarás algunos de los juegos más populares.{" "}
              </p>

              <a className="link-yellow__play" href="/">
                Play
              </a>
            </Col>
            <Col md={6} className="text-center">
              <img
                src="/thumb_image.png"
                alt="Crypto Casino Image"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
        <Container className="mt-5 mb-5">
          <Row>
            <Col md={7}>
              <YoutubeEmbed embedId="54tR06AF9l0" />
            </Col>
            <Col md={5} className="crypto__about">
              <h2>About</h2>
              <p>
                Crypto Casino es un casino con licencia en Curazao que cuenta
                con diversos juegos, que acepta crypto monedas (BTC, USDT y
                TruCash) Tiene soporte al cliente mediante email y Whatsapp. En
                él encontrarás algunos de los juegos más populares.
              </p>
              <p>
                Explora el mundo de los juegos online y averigua todo lo que hay
                para disfrutar en Crypto Casino.
              </p>
              <p>
                Las secciones en Vivo de Crypto Casino permiten que los
                jugadores se diviertan en tiempo real a través del celular o de
                la computadora con los diversos juegos que se ofrecen en alta
                definición.
              </p>
              <p>
                En Crypto Casino los jugadores pueden disfrutar de una gran
                variedad de juegos de casino online, que van desde los clásicos:
                traga monedas, blackjack, póker y juegos de mesa, hasta nuestra
                Lucky Roulette. Además, la mayoría de los juegos incluyen
                funciones de bono.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="crypto__top-games mb-5">
          <Container>
            <Row>
              <Col md={12} className="text-center">
                <h2>Top Games</h2>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={3} sm={3} xs={12} className="mt-3">
                <Card className="crypto-dark-card">
                  <img
                    className="card-img-top"
                    src="/blackjack.jpg"
                    alt="Crypto Casino Image"
                  />

                  <Card.Body className="card-body-title">
                    <Card.Title>Blackjack</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={3} xs={12} className="mt-3">
                <Card className="crypto-dark-card">
                  <img
                    className="card-img-top"
                    src="/shutterstock.jpg"
                    alt="Crypto Casino Image"
                  />

                  <Card.Body className="card-body-title">
                    <Card.Title>Póker</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} sm={3} xs={12} className="mt-3">
                <Card className="crypto-dark-card">
                  <img
                    src="/roulete.jpg"
                    className="card-img-top"
                    alt="Crypto Casino Image"
                  />

                  <Card.Body className="card-body-title">
                    <Card.Title>Roulette Spin</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Homepage;
