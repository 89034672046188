import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={2} className="text-center text-sm-center text-md-start">
            <h4>Games</h4>
            <nav>
              <Link to="/">Bitcoin Games</Link>
            </nav>
          </Col>
          <Col md={2} className="text-center text-sm-center text-md-start">
            <h4>Help</h4>
            <nav className="crypto__list-anclas">
              <Link to="/">FAQ</Link>
              <Link to="/">About Bitcoin</Link>
            </nav>
          </Col>
          <Col md={2} className="text-center text-sm-center text-md-start">
            <h4>Info</h4>
            <nav className="crypto__list-anclas">
              <Link to="/">Casino news</Link>
              <Link to="/">About Us</Link>
              <Link to="/">Terms & Conditions</Link>
            </nav>
          </Col>
          <Col md={6} className="text-center">
            <div className="footer-social-icons">
              <h4 className="_14">Follow us on</h4>
              <ul className="social-icons">
                <li>
                  <a href="" className="social-icon">
                    {" "}
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="" className="social-icon">
                    {" "}
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>

                <li>
                  <a href="" className="social-icon">
                    {" "}
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="" className="social-icon">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
